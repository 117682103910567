/*
 *
 *  bodyスタイル
 *
 */

// 必ず読み込む共通パーツ
@use "ini/variables" as *;
@use "ini/mixins" as *;

@font-face {
  font-family: "Hiragino Sans";
  src: local(HiraginoSans-W3);
  font-weight: normal;
}
@font-face {
  font-family: "Hiragino Sans";
  src: local(HiraginoSans-W6);
  font-weight: bold;
}

html {
  font-size: 1rem;
  height: 100%;
}

body {
  background: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Noto Sans Japanese", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Arial, Meiryo, sans-serif;
  font-weight: normal;
  color: #000;
  line-height: 1.72;
  min-height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  @include mode-transition();
}

.light-theme {
  background: #fff;
}

.svg-load {
  display: none;
}

a {
  color: #0032DB;
}

strong {
  font-weight: bold;
}

em {
  font-weight: bold;
}

del {
  opacity: 0.5;
}


//
// h2, h3, h4
// -----------------
h2, h3, h4 {
  font-weight: bold;
}

h2 {
  font-size: size(28);
}


h3 {
  font-size: size(24);
}

h4 {
  font-size: size(22);
}

h2:not(:first-child),
h3:not(:first-child),
h4:not(:first-child) {
  margin-top: 2.141em;
}

h2:not(:last-child),
h3:not(:last-child),
h4:not(:last-child) {
  margin-bottom: 1.333em;
}


//
// テーブル
// -----------------
table {
  border-color: $l-border-color;

  tr {
    th, td {
      border-color: $l-border-color;
    }
  }

  thead {
    background: rgba($l-border-color, 0.5);
  }
}



// ------------------------------------------------------------------------------------------------------------------
//
//  ダークテーマ
//
// ------------------------------------------------------------------------------------------------------------------
.dark-theme {
  background: #1A1A1A;
  color: $d-text-color;

  a {
    color: #25B1FC;
  }

  //
  // テーブル
  // -----------------
  table {
    border-color: #444;

    tr {
      td, th {
        border-color: #444;
      }
    }

    thead {
      background: rgba(#444, 0.5);
    }
  }
}

