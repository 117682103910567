/*
 *
 *  全体で読み込むスタイル
 *
 */

// リセット
@use "ini/ress" as *;

// 必ず読み込む共通パーツ
@use "ini/variables" as *;
@use "ini/mixins" as *;


@forward "body";

// コンパイル時にコメントアウト
// @forward "parts/wp-style";

// @forward "parts/loading";
@forward "parts/header";
@forward "parts/footer";
@forward "parts/up-button";
@forward "parts/full-menu";
@forward "parts/buttons";
@forward "parts/blog-list";
@forward "parts/form";

@forward "page-common";

@forward "top";
@forward "pages-each";

@forward "blog-home";
@forward "blog-single";
@forward "parts/blog-wp-toc";

@forward "page-privacy";
@forward "qox-block";


:root {
  --content-width: 73.334vw;
  --content-margin: calc((100% - var(--content-width))/ 2);
  --content-margin-plus: 3.333vw;
  --content-inner-narrow-margin: 5.556vw;
  --text-margin-g: 1.618em;
  --text-margin-s: 1.414em;
  --bland-left: 2.5rem;
}

@include mqSmallPC() {
  :root {
    --content-width: 86vw;
    --content-inner-narrow-margin: 0vw;
  }
}

@include mqTAB(){
  :root {
    --content-width: 88vw;
    --content-margin-plus: 0vw;
    --bland-left: calc((100% - var(--content-width))/ 2);
  }
}

@include mqSP(){
  :root {
    --content-width: 90vw;
  }
}