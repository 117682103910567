//
//
//  mixin: table
//
//
@use "../ini/variables" as *;

@mixin table() {
  table {
    border-collapse: collapse;

    &:not(:first-child) {
      margin-top: var(--text-margin-g);
    }
    
    &:not(:last-child) {
      margin-bottom: var(--text-margin-g);
    }

    tr {

      th, td {
        padding: size(16) size(16);
        border-bottom-width: 1px;
        border-bottom-style: solid;
      }

      th {
        font-weight: bold;
      }

      td {
        vertical-align: top;
      }
    }

    thead tr {
      border-top-width: 1px;
      border-top-style: solid;

      th, td {
        padding: size(8) size(16);
      }
    }
  }

  table.table-border {
    tr {
      th, td {
        border-width: 1px;
        border-style: solid;
      }
    }
  }
}