/*
 *
 *  フォームのスタイル
 *
 */

// 必ず読み込む共通パーツ
@use "../ini/variables" as *;
@use "../ini/mixins" as *;


label {
  display: flex;

  > span {
    display: block;
  }
  
  span.require {
    margin-left: auto;
    align-self: flex-start;
    font-size: size(14);
    color: #fff;
    background: #000;
    padding: size(6) size(10);
    line-height: 1;
    white-space: nowrap;

    @include mqSP() {
      margin-left: 1em;
      margin-right: auto;
    }
  }
}
  
input, textarea {
  background-color: #F0F0F0;
  padding: size(8) size(12);
  width: 100%;
}

textarea {
  height: size(192);
}

.error-message {
  display: none;
  padding: size-vh(24);
  color: $l-danger-color;
  background: rgba($l-danger-color, 0.1);
  margin: 0 var(--content-inner-narrow-margin) size-vh(64);
  text-align: center;
}

.error-message.show {
  display: block;
}
  
.invalid-feedback {
  color: $l-danger-color;
  margin-top: size(16);
  font-size: size(14);
}

.form__button-container {
  margin-top: size(32);
  text-align: center;
}


// 
// ダークテーマ
// 
.dark-theme {

  input, textarea {
    background: #383838;
  }

  .error-message {
    background: rgba($l-danger-color, 0.6);
    color: #fff;
  }

  .invalid-feedback {
    color: $d-danger-color;
  }
}


