/*
 *
 *  ブログのスタイル
 *
 */

// 必ず読み込む共通パーツ
@use "ini/variables" as *;
@use "ini/mixins" as *;

// ------------------------------------------------------------------------------------------------------------------
//
//  一覧ヘッダー
//
// ------------------------------------------------------------------------------------------------------------------
.blog-home__header__button-container {
  margin-top: size-vh(32);

  .button {
    height: size(40);
    font-size: size(14);
    width: size(192);
    background: #fff;
  
    svg {
      width: size(14);
      height: size(14);
      margin-left: size(8);
    }
  }
}

.blog-home__header__h1 {
  display: inline-block;
}

.blog-home__header__h2,
.blog-home__header__h2:not(:last-child) {
  font-size: size(24);
  display: inline-block;
  padding-left: size(56);
  margin: 0;

  @include mqSP() {
    font-size: size(20);
    padding-left: size(8);
  }
}

// 
// ダークテーマ
// 
.dark-theme .blog-home__header__button-container{

  .button {
    background: none;
  }
  
  .blog-home__header__h2 {
    color: #fff;
  }
}


// ------------------------------------------------------------------------------------------------------------------
//
//  コンテンツエリア
//
// ------------------------------------------------------------------------------------------------------------------
.blog-home__content {
  padding: size(64) var(--content-margin) size(80);

  &__container {
    padding: 0 var(--content-margin-plus);
  }
}


// ------------------------------------------------------------------------------------------------------------------
//
//  一覧　ページネーション
//
// ------------------------------------------------------------------------------------------------------------------
.blog-home__pagination {
  margin-top: size-vh(72);

  @include mqSP() {
    margin-top: 0;
  }

  ul {
    list-style: none;
    display: flex;
    justify-content: center;

    li {
      flex: 0 0 auto;

      &:not(:first-child) {
        margin-left: size(8);
      }

      a, span {
        text-align: center;
        display: block;
        padding: size(10) size(15);
        line-height: 1rem;
        font-family: Arial, sans-serif;
      }

      a {
        border: 1px solid rgba(#000, 0.1);
        text-decoration: none;
        color: #000;

        &:hover {
          color: #fff;
          background: #000;
          opacity: 1;
        }
      }

      .num {}

      .current {
        background: #eee;
        border: 1px solid #eee;
      }

      .omit {}

      @include mqSP() {
        flex: 1 1 auto;

        a, span {
          padding: size(10) 0;
        }
      }
    }
  }
}

// 
// ダークテーマ
// 
.dark-theme .blog-home__pagination {
  ul li {

    a, span {
      color: $d-text-color;
    }

    a {
      border-color: rgba(#fff, 0.2);

      &:hover {
        background: #fff;
        color: #000;
      }
    }

    .current {
      background: #444;
      border-color: #444;
      color: #fff;
    }
  }
}


// ------------------------------------------------------------------------------------------------------------------
//
//  一覧　カテゴリ・タグ
//
// ------------------------------------------------------------------------------------------------------------------

.blog-home__group {
  display: flex;
  margin-top: size(72);

  @include mqSP() {
    display: block;
  }

  > div {

    h2 {
      margin-bottom: size(24);
      @include raleway(bold);
      font-size: size(26);
    }

    ul {
      list-style: none;

      li {
        font-size: size(15);

        a {
          color: #000;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
            opacity: 1;
          }
        }
      }
    }
  }

  &__category {
    flex: 0 0 25%;

    ul li {
      position: relative;
      padding-left: size(26);
      margin-top: size(20);
      line-height: 1;

      &::before {
        content: '';
        display: block;
        position: absolute;
        background: #CCCCCC;
        width: size(10);
        height: 2px;
        top: 0.5em;
        left: 0;
      }
    }
  }

  &__tag {
    flex: 1 1 auto;

    @include mqSP() {
      margin-top: size-vh(64);
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      margin-right: size(-20);
      margin-bottom: size(-20);

      @include mqSP() {
        margin-right: size(-16);
      }

      li {
        line-height: 1;
        flex: 0 0 auto;
        margin-right: size(20);
        margin-bottom: size(20);
      }
    }
  }
}

// 
// ダークテーマ
// 
.dark-theme .blog-home__group {
  > div {

    h2 {
      color: #fff;
    }
  }

  &__category {
    ul li a {
      color: #fff;
    }
  }

  &__tag {
    ul li a {
      color: $d-tag-color;
    }
  }
}


// ------------------------------------------------------------------------------------------------------------------
//
//  一覧　著者情報
//
// ------------------------------------------------------------------------------------------------------------------
.blog-home__header__author {
  display: flex;
  margin-top: size(48);

  > div:first-child {
    flex: 0 0 size(64);

    @include mqSP() {
      flex: 0 0 size(48);
    }

    img {
      width: 100%;
      border-radius: 50%;
    }
  }

  > div:last-child {
    flex: 1 1 auto;
    padding-left: size(40);

    @include mqSP() {
      padding-left: size(24);
    }

    .blog-home__header__author__name {
      font-size: size(20);
      font-weight: bold;

      small {
        opacity: 0.5;
        font-size: size(14);
        margin-left: size(24);
        font-weight: normal;

        @include mqSP() {
          margin-left: 0;
          display: block;
        }
      }
    }

    .blog-home__header__author__comment {
      margin-top: size-vh(24);
      white-space: pre-line;

      @include mqSP() {
        margin-left: size(-24 - 48);
      }
    }
  }
}

// 
// ダークテーマ
// 
.dark-theme .blog-home__header__author {

  > div:last-child {

    .blog-home__header__author__name {
      color: #fff;

      small {
        opacity: 0.7;
      }
    }
  }
}