//
//
//  mixin: ol/ul
//
//
@use "../ini/variables" as *;

@mixin ol-ul() {
  ol {
    display: table;
    list-style: none;

    &:not(:last-child) {
      margin-top: var(--text-margin-g);
    }

    &:not(:first-child) {
      margin-bottom: var(--text-margin-g);
    }
  
    li {
      display: table-row;
  
      &:not(:last-child)::after {
        content: "";
        display: block;
        margin-bottom: 1em;
      }
  
      &::before {
        content: counter(table-ol) ".";
        display: table-cell;
        padding-right: 0.5em;
        padding-left: 0.25em;
        text-align: right;
        white-space: nowrap;
      }
    }
  
    > li {
      counter-increment: table-ol;
  
      &:before {
        content: counter(table-ol) ".";
      }

      ol {
        margin-top: var(--text-margin-g);
      }
  
      > ol > li {
        counter-increment: table-ol2;
  
        &:before {
          content: counter(table-ol2) ".";
        }
  
        > ol > li {
          counter-increment: table-ol3;
  
          &:before {
            content: counter(table-ol3) ".";
          }
        }
      }
    }
  }

  ul {
    list-style-type: disc;
    margin-left: 1.5em; 

    &:not(:last-child) {
      margin-top: var(--text-margin-g);
    }

    &:not(:first-child) {
      margin-bottom: var(--text-margin-g);
    }

    li {
      display: list-item;

      &::before {
        content: none;
      }
    }
  }

  ol li, ul li {
    margin-bottom: 0.75em;
  }
}