/*
 *
 *  ヘッダーのスタイル
 *
 */

// 必ず読み込む共通パーツ
@use "../ini/variables" as *;
@use "../ini/mixins" as *;

@keyframes full-menu_fadeIn-logo {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes full-menu_fadeOut-logo {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}

// ------------------------------------------------------------------------------------------------------------------
//
//  ロゴ
//
// ------------------------------------------------------------------------------------------------------------------
.brand-logo {
  position: absolute;
  left: var(--bland-left);
  top: size(31);
  z-index: 1003;
  transition: 0.3s ease-in;

  @include mqSP() {
    top: size(31 - 9);
  }

  a {
    color: #000;
    display: block;
    font-size: 0;

    .brand-logo__img {
      width: size(100);
      height: size(29);
      // @include mode-transition();
    }
  }
}

input#full-menu:checked ~ .brand-logo {
  position: fixed;
  
  a .brand-logo__img {

    &.scroll {
      animation: full-menu_fadeIn-logo 0.5s forwards;
    }

    @include mqSP() {
      fill: #fff;
    }
  }
}

// ------------------------------------------------------------------------------------------------------------------
//
//  メニュー
//
// ------------------------------------------------------------------------------------------------------------------
header {
  position: absolute;
  top: 0;
  left: var(--content-margin);
  width: var(--content-width);
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: size(30);

  @include mqSmallPC() {
    padding-right: size(24);
  }

  @include mqTAB() {
    padding-right: size(40);
  }

  @include mqSP() {
    display: none;
  }

  //
  // モード切替
  // -----------------
  .header__mode {
    width: size(24);
    height: size(24);

    label {
      width: size(24);
      height: size(24);
      display: block;
      cursor: pointer;
      position: relative;

      &::before {
        position: absolute;
        content: '';
        display: block;
        background: $l-hover-color;
        border-radius: 50%;
        width: size(40);
        height: size(40);
        top: size(-8);
        left: size(-8);
        opacity: 0;
        transition: all 0.3s ease;
      }

      &:hover::before {
        opacity: 1;
        transition: all 0.3s ease;
      }

      svg {
        width: size(24);
        height: size(24);
        position: relative;
      }
    }

    input#header-mode-button {
      display: none;

      &:not(:checked) ~ label {
        .moon {
          fill: #000;
        }
        .sun {
          display: none;
        }
      }

      &:checked ~ label {
        .moon {
          display: none;
        }
        .sun {
          fill: #fff;
        }
      }
    }
  }

  ul {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    li {
      padding-left: size(40);

      a {
        display: block;
        color: #000;
        // @include raleway(semibold);
        font-weight: bold;
        text-decoration: none;
        font-size: size(16);
        @include hover-underline('slide', 'left-right', 2px, #000);
        position: relative;

        // span {
        //   position: absolute;
        //   display: inline-block;
        //   top: size(-14);
        //   left: 50%;
        //   transform: translateX(-50%) scale(0.9);
        //   font-size: size(10);
        //   font-weight: normal;
        //   white-space: nowrap;
        //   opacity: 0.7;
        //   letter-spacing: 0.25em;
        // }
      }
    }
  }
}


//
//  ダークテーマ
//
.dark-theme .brand-logo a .brand-logo__img {
  fill: #fff;
}

.dark-theme header {
  
  .header__mode label::before {
    background: rgba(#fff, 0.1);
  }

  ul li a {
    color: $l-border-color;
    @include hover-underline('slide', 'left-right', 2px, $l-border-color);
  }
}
