/*
 *
 *  ブログのリスト
 *
 */

// 必ず読み込む共通パーツ
@use "../ini/variables" as *;
@use "../ini/mixins" as *;

ul.blog-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  @include mqSP() {
    display: block;
  }

  li {
    flex: 0 0 30%;
    padding-bottom: size-vh(72);

    &:not(:nth-child(3n)) {
      margin-right: 5%;
    }

    @include mqSP() {

      &:not(:nth-child(3n)) {
        margin-right: 0;
      }
    }

    > a:first-child {
      display: block;
      text-decoration: none;
      color: #000;
      position: relative;

      &::before {
        position: absolute;
        content: '';
        display: block;
        background: $l-hover-color;
        top: -1.4vw;
        left: -1.4vw;
        right: -1.4vw;
        bottom: -1.0vw;
        border-radius: 2px;
        z-index: -1;
        opacity: 0;
        transition: all 0.3s ease-out;
      }

      &:hover {
        &::before {
          opacity: 1;
          transition: all 0.3s ease-out;
        }
        
        > div > div {
          transform: translate(-50%, -50%) scale(1.06);
          transition: transform 0.3s;
        }
      }

      .blog-list__image-wrap {
        width: 100%;
        padding-top: 68.75%;
        overflow: hidden;
        position: relative;

        &::after {
          position: absolute;
          content: '';
          display: block;
          top: 0;
          left: 0; 
          right: 0;
          bottom: 0;
          box-shadow: 0 0 0 1px rgba(#000, 0.08) inset;
        }

        > div {
          position: absolute;
          width: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 0;
          background-size: cover;
          background-position: center;
          height: 100%;
          transition: transform 0.3s;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          overflow: hidden;
        }
      }

      > p {
        margin-top: size(20);
        display: flex;
        align-items: center;

        span {
          display: block;
          line-height: 1;

          &:nth-child(1) {
            font-size: size(15);
            padding-right: size(10);
            border-right: 1px solid #000;
            font-weight: bold;
          }

          &:nth-child(2) {
            font-size: size(14);
            padding-left: size(10);
            font-family: Arial;
          }
        }
      }

      h3 {
        margin: size(20) 0 0;
        font-size: size(18);
        font-weight: bold;
      }
    }

    .blog-list__tags {
      margin-top: 1.2vw;
      line-height: 1;

      a {
        color: rgba(#000, 0.4);
        font-size: size(13);
        display: inline-block;
        text-decoration: none;
        font-family: 'Raleway', "Noto Sans Japanese", "Hiragino Sans", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
        font-weight: 300;

        &:not(:first-child) {
          margin-left: 0.5em;
        }

        &:hover {
          text-decoration: underline;
          opacity: 1;
        }
      }
    }
  }
}

// 
// ダークテーマ
// 
.dark-theme ul.blog-list {
  li {
    > a:first-child {

      &::before {
        background: $d-hover-color;
      }

      > p {
        color: $d-text-color;

        span:nth-child(1) {
          border-right-color: #666;
        }
      }

      h3 {
        color: #fff;
      }
    }

    .blog-list__tags {
      a {
        color: $d-tag-color;
      }
    }
  }
}