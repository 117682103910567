/*
 *
 *  ボタン
 *
 */

// 必ず読み込む共通パーツ
@use "../ini/variables" as *;
@use "../ini/mixins" as *;

@mixin l-button() {
  box-shadow: 0 0 0 2px #000 inset;
  height: size(48);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: size(278);
  text-decoration: none;
  font-weight: bold;
  position: relative;
  transition: all 0.3s;
  color: #000;

  @include mqSP() {
    width: 100%;
  }

  &::after {
    height: 100%;
    left: 0;
    top: 0;
    width: 0;
    background: #000;
    content: '';
    position: absolute;
    transition: all 0.3s;
  }

  &:hover {
    color: #fff;

    &::after {
      width: 100%;
    }
  }

  span {
    display: block;
    z-index: 10;
  }

  svg {
    width: size(20);
    height: size(20);
    margin-left: size(12);
    z-index: 11;
  }
}

@mixin d-button() {
  box-shadow: 0 0 0 2px rgba(#fff, 0.4) inset;
  color: #fff;

  &::after {
    background: #fff;
  }

  &:hover {
    color: #000;
  }
}


// ------------------------------------------------------------------------------------------------------------------
//
//  通常のボタン
//
// ------------------------------------------------------------------------------------------------------------------
.button,
.form__button-container button {
  @include l-button();
}


// 
// ダークテーマ
// 
.dark-theme .button,
.dark-theme .form__button-container button
 {
  @include d-button();
}


// 不活性
.button.button--disabled,
.button:disabled,
.form__button-container button:disabled {
  opacity: 0.24;
  cursor: not-allowed;

  &:hover {
    color: inherit;
  }

  &::after {
    content: none;
  }
}

.button.button.button--inverse.button--disabled {
  opacity: 0.6;
}


// ------------------------------------------------------------------------------------------------------------------
//
//  反転ボタン
//
// ------------------------------------------------------------------------------------------------------------------
.button.button--inverse,
.form__button-container button.button--inverse {
  box-shadow: 0 0 0 1px #fff inset;
  color: #fff;

  &::after {
    background: #fff;
  }

  &:hover {
    color: #333;
  }
}

// 不活性
.button.button--inverse.button--disabled,
.button.button--inverse:disabled,
.form__button-container button.button--inverse:disabled {
  &:hover {
    color: #fff;
  }
}

// 
// ダークテーマ
// 
.dark-theme .button.button--inverse,
.dark-theme .form__button-container button.button--inverse {
  box-shadow: 0 0 0 1px rgba(#fff, 0.4) inset;

  &:hover {
    color: #000;
  }
}

// 不活性
.dark-theme .button.button--inverse.button--disabled,
.dark-theme .button.button--inverse:disabled,
.dark-theme .form__button-container button.button--inverse:disabled {
  &:hover {
    color: #fff;
  }
}
