/*
 *
 *  フルサイズメニュー
 *
 */

// 必ず読み込む共通パーツ
@use "../ini/variables" as *;
@use "../ini/mixins" as *;

$speed: 0.5;

@keyframes full-menu_fadeIn-bg {
  0% {
    left: 100%;
    opacity: 100%;
  }
  42% {
    left: 33%;
  }
  45% {
    left: 33%;
  }
  100% {
    left: 66.6%;
  }
}

@keyframes full-menu_fadeIn-bg_tab {
  0% {
    left: 100%;
    opacity: 100%;
  }
  42% {
    left: 25%;
  }
  45% {
    left: 25%;
  }
  100% {
    left: 56%;
  }
}

@keyframes full-menu_fadeIn-bg_sp {
  0% {
    left: 100%;
    opacity: 100%;
  }
  42% {
    left: 0;
  }
  45% {
    left: 0;
  }
  100% {
    left: 0;
  }
}


@keyframes full-menu_fadeIn-text {
  0% {
    overflow: hidden;
    width: 0;
  }
  100% {
    overflow: visible;
    width: 100%;
  }
}

@keyframes full-menu_fadeOut-bg {
  0% {
    opacity: 1;
    left: 66.6%;
  }
  100% {
    opacity: 0;
    left: 100%;
  }
}

@keyframes full-menu_fadeOut-bg_tab {
  0% {
    opacity: 1;
    left: 56%;
  }
  100% {
    opacity: 0;
    left: 100%;
  }
}

@keyframes full-menu_fadeOut-bg_sp {
  0% {
    opacity: 1;
    left: 0;
  }
  100% {
    opacity: 0;
    left: 100%;
  }
}

@keyframes full-menu_fadeOut-text {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}


// ------------------------------------------------------------------------------------------------------------------
//
//  アイコン
//
// ------------------------------------------------------------------------------------------------------------------
input#full-menu {
  display: none;
}

input#full-menu ~ label.full-menu__label {
  position: fixed;
  z-index: 1001;
  right: size(40);
  top: size(36);
  width: size(20);
  height: size(17);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform #{$speed * 0.3s} $full-menu_icon_transition;

  @include mqSP() {
    top: size(40 - 12);
    right: size(24);
  }

  &::before {
    position: absolute;
    content: '';
    display: block;
    background: $l-hover-color;
    border-radius: 50%;
    width: size(40);
    height: size(40);
    top: size(-12);
    left: size(-10);
    opacity: 0;
    transition: all #{$speed * 0.3s} ease;
  }

  &:hover::before {
    opacity: 1;
    transition: all #{$speed * 0.3s} ease;
  }

  span {
    display: block;
    width: 100%;
    height: 1px;
    background: #000;
    transform: scaleX(1);
    transition: #{$speed * 0.5s} $full-menu_icon_transition;
    transition-delay: #{$speed * 0.4s};
    transition-property: transform;

    &:last-child {
      width: 75%;
      transition: #{$speed * 0.5s} $full-menu_icon_transition;
      transition-delay: #{$speed * 0.4s};
      transition-property: width;
    }
  }

  &::after {
    content: '';
    display: block;
    width: 1px;
    height: 0;
    background-color: #000;
    position: absolute;
    top: size(-2);
    right: size(10);
    transition: height #{$speed * 0.4s} $full-menu_icon_transition;
    transition-property: height;
    z-index: 10;
  }
}

input#full-menu:checked ~ label.full-menu__label {
  transform: rotate(45deg) scale(1.1);
  transition-delay: #{$speed * 0.4s};

  &::before {
    background: rgba(#fff, 0.2);
  }

  span {
    background: #fff;

    &:first-child {
      transform: scaleX(0);
      transition: transform #{$speed * 0.2s} $full-menu_icon_transition;
    }

    &:nth-child(3) {
      width: 0;
      transition: width #{$speed * 0.2s} $full-menu_icon_transition;
    }
  }

  &::after {
    background-color: #fff;
    height: size(20);
    width: 1px;
    transition: height #{$speed * 0.2s} $full-menu_icon_transition;
    transition-delay: #{$speed * 0.3s};
  }
}


// ------------------------------------------------------------------------------------------------------------------
//
//  メニュー　デフォルト
//
// ------------------------------------------------------------------------------------------------------------------
.full-menu__container {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 100%;
  bottom: 0;
  background: #F9FAF8;
  transition: #{$speed * 0.65s} ease-in;
  transition-property: left, opacity;
  opacity: 0;
  transition-delay: #{$speed * 0.5s};

  .full-menu__container__inner {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    opacity: 1;
    left: 66.6%;
    background: $gradient-base;
    background: $gradient-135deg;
    animation: full-menu_fadeOut-bg #{$speed * 1s} forwards;
    animation-delay: #{$speed * 0.3s};

    @include mqTAB() {
      animation: full-menu_fadeOut-bg_tab #{$speed * 1s} forwards;
    }

    @include mqSP() {
      animation: full-menu_fadeOut-bg_sp #{$speed * 1s} forwards;
    }

    > div {
      padding-top: size-vh(108);
      padding-left: 20%;
      animation: full-menu_fadeOut-text #{$speed * 1s} forwards;
      animation-delay: #{$speed * 0.2s};

      @include mqSP() {
        padding-top: size-vh(144);
        padding-left: calc(var(--bland-left) + 2rem);
      }
    }

    ul {
      list-style: none;

      li {
        white-space: nowrap;
        opacity: 0;
        transition: opacity #{$speed * 0.3s} linear;
        transition-delay: #{$speed * 0.1s};

        &:nth-child(1) {
          transition-delay: #{$speed * 0.1s};
        }
        &:nth-child(2) {
          transition-delay: #{$speed * 0.2s};
        }
        &:nth-child(3) {
          transition-delay: #{$speed * 0.3s};
        }
        &:nth-child(4) {
          transition-delay: #{$speed * 0.4s};
        }
        &:nth-child(5) {
          transition-delay: #{$speed * 0.5s};
        }

        &:not(:last-child) {
          margin-bottom: size-vh(32);
        }

        a {
          color: #fff;
          text-decoration: none;
          line-height: 1;
          @include hover-underline('slide', 'left-right', 4px, #fff);

          &::after {
            bottom: 20px;
          }

          .full-menu__ja {
            font-size: size-vh(31);
            font-weight: bold;
          }

          .full-menu__en {
            @include raleway(bold);
            font-size: size(16);
            opacity: 0.8;
            margin-top: size(12);

            @include mqSP() {
              margin-top: size(8);
            }
          }
        }
      }
    }

    // プライバシーポリシー
    .full-menu__container__policy {
      margin-top: size-vh(56);
      white-space: nowrap;

      a {
        @include raleway(bold);
        color: #fff;
        text-decoration: none;
        font-size: size(24);
        line-height: 1.2;
        @include hover-underline('slide', 'left-right', 3px, #fff);
      }
    }

    // モード選択
    .full-menu__container__mode {
      display: flex;
      margin-top: size-vh(54);

      > div {
        margin-right: size(20);
        cursor: pointer;
        position: relative;

        &::before {
          position: absolute;
          content: '';
          display: block;
          background: rgba(#fff, 0.2);
          width: size(40);
          height: size(40);
          border-radius: 50%;
          left: size(-8);
          top: size(-8);
          opacity: 0;
          transition: opacity #{$speed * 0.3s} ease-out;
        }

        &:hover::before {
          opacity: 1;
          transition: opacity #{$speed * 0.3s} ease-out;
        }

        svg {
          width: size(24);
          height: size(24);
          fill: #fff;
        }
      }
    }

    .full-menu__container__policy,
    .full-menu__container__mode {
      opacity: 0;
      transition: opacity #{$speed * 0.3s} linear;
      transition-delay: #{$speed * 0.7s};
    }
  }
}

// ------------------------------------------------------------------------------------------------------------------
//
//  メニュー開く
//
// ------------------------------------------------------------------------------------------------------------------
input#full-menu:checked ~ .full-menu__container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: #{$speed * 0.35s} ease-in;
  transition-property: left;

  .full-menu__container__inner {
    opacity: 1;
    left: 100%;
    animation: full-menu_fadeIn-bg #{$speed * 1s} forwards;
    animation-delay: #{$speed * 0.3s};

    @include mqTAB() {
      animation: full-menu_fadeIn-bg_tab #{$speed * 1s} forwards;
    }

    @include mqSP() {
      animation: full-menu_fadeIn-bg_sp #{$speed * 1s} forwards;
    }

    > div {
      animation: full-menu_fadeIn-text #{$speed * 1s} forwards;
      animation-delay: #{$speed * 1s};

      ul li {
        opacity: 1;
        transition: opacity #{$speed * 0.5s} linear;
        transition-delay: #{$speed * 1s};

        &:nth-child(1) {
          transition-delay: #{$speed * 1.2s};
        }
        &:nth-child(2) {
          transition-delay: #{$speed * 1.3s};
        }
        &:nth-child(3) {
          transition-delay: #{$speed * 1.4s};
        }
        &:nth-child(4) {
          transition-delay: #{$speed * 1.5s};
        }
        &:nth-child(5) {
          transition-delay: #{$speed * 1.6s};
        }
      }

      .full-menu__container__policy,
      .full-menu__container__mode {
        opacity: 1;
        transition: opacity #{$speed * 0.5s} linear;
        transition-delay: #{$speed * 1.5s};
      }
    }
  }
}


//
//  ダークテーマ
//
.dark-theme {
  input#full-menu ~ label span {
    background: #fff;
  }

  .full-menu__container {
    background: #262626;
  }
}