/*
 *
 *  上に戻るボタン
 *
 */

// 必ず読み込む共通パーツ
@use "../ini/variables" as *;
@use "../ini/mixins" as *;

#up-button {
  width: size(40);
  height: size(90);
  text-align: center;
  cursor: pointer;
  position: fixed;
  bottom: size(48);
  right: 5%;
  opacity: 0;
  transition: all 0.3s ease;

  &.show {
    opacity: 1;
    transition: all 0.3s ease;
  }

  svg {
    width: size(24);
    height: size(90);
  }
}

// 
// ダークテーマ
// 
.dark-theme #up-button {
  color: #fff;
}