/*
 *
 *  フッターのスタイル
 *
 */

// 必ず読み込む共通パーツ
@use "../ini/variables" as *;
@use "../ini/mixins" as *;

// .top__link + footer {
//   border-top: 0;
// }

footer {
  border-top: 1px solid $l-border-color;
  padding: size(48) var(--content-margin);
  display: flex;
  margin-top: auto;

  @include mode-transition();

  > div:first-child {
    flex: 0 0 var(--content-margin-plus);
    min-width: size(48);

    svg {
      width: size(24);
      height: size(34);
    }
  }

  > div:last-child {
    flex: 1 1 auto;
    //padding-left: size(24);

    .footer__company-name {
      font-weight: bold;
      font-size: size(16);
      line-height: 1;
    }

    .footer__address {
      font-size: size(13);
      margin-top: size(16);
    }

    .footer__link {
      margin-top: size(20);
      font-size: size(13);
      line-height: 1;
      display: flex;
      align-items: center;

      > a {
        display: block;
      }

      a.footer__link--text {
        text-decoration: none;
        color: #000;

        &:hover {
          text-decoration: underline;
        }
      }

      a.footer__link--image {
        margin-left: size(16);
        font-size: 0;

        img {
          width: size(20);
        }

        .fb-dark {
          display: none;
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .footer__copy {
      margin-top: size(32);
      font-size: size(14);
    }
  }
}

// 
// ダークテーマ
// 
.dark-theme footer {
  background: #252525;
  border-top: 0;

  > div:first-child {
    color: #fff;
  }

  > div:last-child {

    .footer__link{
      a.footer__link--text {
        color: #fff;
      }

      a.footer__link--image {
        .fb-light {
          display: none;
        }

        .fb-dark {
          display: block;
        }
      }
    }
  }
}