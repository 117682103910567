/*
 *
 *  ブログ記事のスタイル
 *
 */

// 必ず読み込む共通パーツ
@use "ini/variables" as *;
@use "ini/mixins" as *;

// リスト
@use "parts/mixins-ol-ul" as *;

// テーブル
@use "parts/mixins-table" as *;


// ------------------------------------------------------------------------------------------------------------------
//
//  ヘッダー
//
// ------------------------------------------------------------------------------------------------------------------
.blog-single__header {
  background: #F9FAF8;
  padding: size-vh(115) var(--content-margin) size(182);
  @include mode-transition();

  &__container {
    padding: 0 var(--content-margin-plus);
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;

    .blog-single__header__text {
      @include raleway(bold);
      font-size: size(90);
      line-height: 1.5;
      position: relative;
      display: inline-block;

      &::before {
        position: absolute;
        content: '';
        display: block;
        width: size(48);
        height: 3px;
        background: $gradient-deep-90deg;
        left: 0;
        bottom: size-vh(12);
      }

      @include mqSP() {
        font-size: size-vw(180);

        &::before {
          bottom: size-vh(4);
          width: 0.75em;
        }
      }
    }

    .blog-single__header__text--ja {
      line-height: 1;
      padding-top: size(16);
      flex: 0 0 100%;

      @include mqSP() {
        font-size: size(14);
      }
    }

    .blog-single__header__back {
      display: inline-block;
      padding-left: size(56);
      font-size: 90%;

      a {
        color: rgba(#000, 0.8);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

// 
// ダークテーマ
// 
.dark-theme .blog-single__header {
  background: #131313;

  &__container {

    .blog-single__header__text {
      color: #fff;
    }

    .blog-single__header__back a {
      color: rgba(#fff, 0.7);
    }
  }
}

// ------------------------------------------------------------------------------------------------------------------
//
//  タイトル部分
//
// ------------------------------------------------------------------------------------------------------------------
.blog-single__title {
  // background: #262626;
  background: $gradient-90deg;
  margin-top: size(-182 + 48);
  margin-left: var(--content-margin);
  padding-right: var(--content-margin);
  overflow: hidden;
  position: relative;

  @include mqSP() {
    padding-right: 0;
  }

  &__container {
    margin: size-vw(48) var(--content-margin-plus);
    display: flex;
    position: relative;

    @include mqTAB() {
      margin: size-vh(32);
    }

    @include mqSP() {
      display: block;
    }

    //
    // 画像
    // -----------------
    .blog-single__title__imageWrap {
      flex: 0 0 size-vh(320);

      // @include mqTAB() {
      //   flex: 0 0 size-vh(320);
      // }

      .blog-single__title__image {
        width: 100%;
        display: block;
        box-shadow: 0px 1px 12px -2px rgba(#000, 0.2), 0px 13px 35px -10px rgba(#000, 0.2);
      }
    }

    //
    // テキスト
    // -----------------
    .blog-single__title__text {
      padding-left: size-vw(52);
      padding-right: var(--content-margin-plus);
      color: #fff;

      @include mqSP() {
        padding-left: 0;
        margin-top: size-vh(32);
      }

      h1 {
        font-size: size-vw(24);

        @include mqTAB() {
          font-size: size-vh(24);
        }
      }

      .blog-single__title__detail {
        display: flex;
        margin-top: size(24);
        align-items: center;
        flex-wrap: wrap;

        @include mqTAB() {
          font-size: size-vh(16);
        }

        &__category {
          line-height: 1;
          font-size: size-vw(15);
          padding-right: size(10);
          border-right: 1px solid #fff;
          font-weight: bold;
          flex: 0 0 auto;
          margin-bottom: auto;
          white-space: nowrap;

          a {
            color: #fff;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        &__date {
          line-height: 1;
          font-size: size-vw(14);
          padding-left: size(10);
          font-family: Arial;
          flex: 0 0 auto;
          margin-bottom: auto;
          white-space: nowrap;
        }

        &__tags {
          font-size: size-vw(14);
          line-height: 1;
          flex: 0 0 100%;
          margin-top: size(16);

          a {
            display: inline-block;
            text-decoration: none;
            font-family: 'Raleway', "Noto Sans Japanese", "Hiragino Sans", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
            font-weight: 300;
            color: #fff;
            opacity: 0.7;
            margin-bottom: size(16);
    
            &:not(:last-child) {
              margin-right: 0.5em;
            }
    
            &:hover {
              text-decoration: underline;
              opacity: 1;
            }
          }
        }

        @include mqSP() {
          .blog-single__title__detail__category {
            font-size: size(14);
          }
          .blog-single__title__detail__date,
          .blog-single__title__detail__tags {
            font-size: size(13);
          }
        }
      }

      //
      // 著者
      // -----------------
      .blog-single__title__author {
        display: flex;
        //margin-top: size(48);
        margin-top: size(32);

        > div:first-child {
          flex: 0 0 size(32);
          line-height: 0;
      
          img {
            width: 100%;
            height: auto;
            border-radius: 50%;
            line-height: 0;
          }
        }
      
        > div:last-child {
          flex: 1 1 auto;
          padding-left: size(20);
          line-height: 1;
          display: flex;
          flex-direction: column;
      
          a {
            font-size: size-vw(14);
            font-weight: bold;
            color: #fff;
            text-decoration: none;
            display: block;

            @include mqSP() {
              font-size: size(13);
            }

            &:hover {
              text-decoration: underline;
            }

            &:last-child {
              margin-top: auto;
              margin-bottom: auto;
            }
          }
      
          small {
            display: block;
            opacity: 0.8;
            font-size: size-vw(12);
            font-weight: normal;
            line-height: 1;
            margin-top: size-vh(12);
            opacity: 0.8;

            @include mqSP() {
              margin-top: size-vh(16);
              font-size: size(11);
            }
          }
        }
      }
    }
  }
}

// 
// ダークテーマ
// 
.dark-theme .blog-single__title {
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#000, 0.1);
  }
//   background: #000;

//   .blog-single__title__text {
//     color: $d-text-color;

//     h1 {
//       color: #fff;
//     }

//     .blog-single__title__detail {

//       &__category {
//         border-color: #666;
//       }

//       &__tags {
//         a {
//           color: $d-tag-color;
//           opacity: 1;
//         }
//       }
//     }

//     .blog-single__title__author {
//       > div:last-child {
//         a {
//           color: $d-text-color;
//         }
//       }
//     }
//   }
}


// ------------------------------------------------------------------------------------------------------------------
//
//  コンテンツエリア
//
// ------------------------------------------------------------------------------------------------------------------
.blog-single__content {
  padding: size-vh(48) var(--content-margin) size-vh(80);

  &__container {
    padding: 0 var(--content-margin-plus);


    // ------
    // 記事の中身
    // ------
    h2 {
      padding-bottom: size(2);
      border-bottom: 2px solid #000;
    }

    h3 {
      background: #f0f0f0;
      padding: size(2) size(12);
    }

    p {
      line-height: 2.25;
    }

    p + p,
    p + figure,
    p + img,
    p + pre,
    p + div {
      margin-top: var(--text-margin-s);
    }

    a:not(.button):hover {
      opacity: 0.7;
    }

    .syntaxhighlighter,
    .wp-block-code,
    .wp-block-quote,
    blockquote,
    figure {
      margin: var(--text-margin-g) 0;
    }

    pre.wp-block-code {
      white-space: pre-wrap;
    }

    code {
      background: #000;
      color: #fff;
      padding: size(8) size(12);
      display: block;
    }

    p code,
    li code {
      display: inline-block;
      padding: 0 size(10);
      margin: 0 size(8);
      font-size: 94%;
      background: rgba(#000, 0.05);
      color: $gradient-base2;
    }

    img {
      height: auto;
    }

    // 画像ギャラリーアイテムのキャプションを除く
    figcaption:not([class$="item__caption"] ) {
      font-size: 85%;
      opacity: 0.5;
    }

    blockquote {
      background: #F8F8F8;
      color: rgba(#000, 0.7);
      border-color: #000;
      border-style: solid;
      border-width: 0 0 0 2px;
      padding: size(16) size(20);

      p {
        margin-top: 0.5em;
      }

      cite {
        font-size: 90%;
        color: rgba(#000, 0.5);
        margin-top: size(16);
        display: block;
      }
    }

    .wp-block-pullquote blockquote {
      margin: 0;
      border-width: 2px 0 2px 0;
    }

    // リスト設定
    @include ol-ul();

    ul, ol {
      line-height: 2;
    }

    // テーブル設定
    @include table();


    // ------
    // SNS シェア
    // ------
    .addtoany_content {
      margin-top: size(64);
    }


    // ------
    // 広告
    // ------
    .blog-single__content__ad {
      margin-top: size(48);
    }


    // ------
    // 著者
    // ------
    .blog-single__content__author {
      display: flex;
      border: 1px solid rgba(#000, 0.15);
      padding: size-vh(24);
      margin-top: size(56);

      > div:first-child {
        flex: 0 0 size(90);

        @include mqSP() {
          flex: 0 0 size(48);
        }

        img {
          width: 100%;
          height: auto;
          border-radius: 50%;
        }
      }

      > div:last-child {
        flex: 1 1 auto;
        padding-left: size(36);

        @include mqSP() {
          padding-left: size(24);
        }

        p {
          line-height: 1.7;
          font-size: size(14);
          margin-top: size(12);

          &.blog-single__content__author__name {
            font-size: size(15);
            font-weight: bold;
            margin: 0;

            a {
              color: #000;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
                opacity: 1;
              }
            }
          }

          &.blog-single__content__author__position {
            font-size: size(13);
            opacity: 0.6;
            margin-top: size(8);
          }

          &.blog-single__content__author__description {
            white-space: pre-line;
          }
        }
      }
    }

    // ------
    // 戻るボタン
    // ------
    .blog-single__button-container {
      text-align: center;
      margin-top: size-vh(64);

      svg {
        transform: rotate(180deg);
        margin-right: 0.75rem;
        margin-left: 0;
      }
    }
  }
}

// 
// ダークテーマ
// 
.dark-theme .blog-single__content .blog-single__content__container {

  h2 {
    color: #fff;
    border-color: #fff;
  }

  h3 {
    color: #fff;
    background: #333;
  }

  h4 {
    color: #fff;
  }

  blockquote {
    background: #252525;
    color: rgba($d-text-color, 0.8);
    border-color: rgba(#fff, 0.5);

    cite {
      color: rgba($d-text-color, 0.5);
    }
  }

  p code,
  li code {
    background: rgba(#fff, 0.1);
  }

  .blog-single__content__author {
    border-color: #444;

    p.blog-single__content__author__name a {
      color: #fff;
    }

    &.blog-single__content__author__position {
      color: #fff;
      opacity: 0.8;
    }
  }
}