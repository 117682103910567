/*
 *
 *  プライバシーポリシー固有のスタイル
 *
 */

// 必ず読み込む共通パーツ
@use "ini/variables" as *;
@use "ini/mixins" as *;

.page-privacy__container {

  > ol > li {

    > ol > li {
      counter-increment: table-ol2;

      &:before {
        content: "(" counter(table-ol2) ")";
      }

      > ol {

        > li {
          counter-increment: table-ol3;

          &:before {
            content: counter(table-ol3, katakana) ".";
          }

          p:not(:last-child) {
            margin-bottom: 0.5em;
          } 
        }
      }
    }
  }

  @include mqSP() {
    ol > li {
      display: block;

      &::before {
        display: inline; 
      }

      p {
        display: inline; 
      }
    }
  }
}

// ------------------------------------------------------------------------------------------------------------------
//
//  利用目的テーブル
//
// ------------------------------------------------------------------------------------------------------------------
.page-privacy__container {
  .page-privacy__table--purpose.table-border {

    tr {
      th, td {
        border-width: 2px;
      }

      th:nth-child(1) {
        width: 24%;
      }
      th:nth-child(2) {
        width: 38%;
      }
      th:nth-child(3) {
        width: 38%;
      }
    }

    @include mqSP() {
      border-bottom-width: 2px;
      border-bottom-style: solid;

      tr {

        th {
          display: none;
        }

        td {
          display: block;
          border-bottom-width: 0;

          &::before {
            content: attr(data-label);
            display: block;
            font-weight: bold;
            margin-bottom: 0.5em;
          }

          &:not(:first-child) {
            border-top-width: 0;
            padding-top: 0.5em;
          }
        }
      }
    }
  }
}