/*
 *
 *  どこでも使えるGutenbergブロックパーツ用
 *
 */

// 必ず読み込む共通パーツ
@use "ini/variables" as *;
@use "ini/mixins" as *;


// ------------------------------------------------------------------------------------------------------------------
//
//  アプリ紹介ブロック
//
// ------------------------------------------------------------------------------------------------------------------
.qox-block-app,
.wp-block-columns.qox-block-app {
  display: flex;
  flex-wrap: nowrap;

  &:not(:last-child) {
    margin-bottom: size(72);
  }

  .wp-block-column, figure {
    margin-bottom: 0;
  }

  .wp-block-column:nth-child(1) {
    flex: 0 0 size(128);
    margin-right: size(18);

    > figure img {
      width: 100%;
      height: auto;
    }
  }

  .wp-block-column:nth-child(2) {
    margin-left: size(18);
    flex: 1 1 auto;
  }

  @include mqSP() {
    display: block;

    > .wp-block-column:nth-child(1) {
      margin-right: 0;
      flex-basis: size(128)!important;

      > figure img {
        width: size(128)!important;
      }
    }

    > .wp-block-column:nth-child(2) {
      margin-top: 1rem;
      margin-left: 0;
      flex: 0 0 100%;
    }
  }
}

// バッジ
.qox-block-app-badge,
.wp-block-columns.qox-block-app-badge {
  margin-left: size(-10);
  margin-top: size(-10);
  margin-bottom: 0;
  display: flex;
  flex-wrap: nowrap;

  .wp-block-column:nth-child(1) {
    margin-right: 0;
    flex: 0 0 auto!important;
  }

  .wp-block-column:nth-child(2) {
    margin-left: 0;
    flex: 0 0 auto!important;
  }

  .wp-block-column:nth-child(1),
  .wp-block-column:nth-child(2) {

    p {
      margin: 0;
    }

    a {
      text-decoration: none;

      &:hover,
      &:hover figure {
        opacity: 0.7;
      }
    }
    
    figure img {
      height: size(68);
      width: auto;
    }
  }
}