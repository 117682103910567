//
//
//  mixin
//
//
@use "sass:math";
@use "variables" as *;

// ------------------------------------------------------------------------------------------------------------------
//
//  簡易メディアクエリ
//
// ------------------------------------------------------------------------------------------------------------------
@mixin mqSmallPC() {
  @media screen and (max-width: 1280px) {
    @content;
  }
}

@mixin mqTAB() {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin mqSP() {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin mqSmallSP() {
  @media screen and (max-width: 360px) {
    @content;
  }
}



// ------------------------------------------------------------------------------------------------------------------
//
//  font-sizeをremにする
//
// ------------------------------------------------------------------------------------------------------------------
$base-size: 16;

@function size($target, $context: $base-size) {
  @return math.div($target , $context) * 1rem;
}

//vwで指定
@function size-vw($target) {
  @return math.div($target , 1440) * 100vw;
}

@function size-vh($target) {
  @return math.div($target , 900) * 100vh;
}

// ------------------------------------------------------------------------------------------------------------------
//
//  フォント指定 Raleway
//
// ------------------------------------------------------------------------------------------------------------------
@mixin raleway($weight: regular) {
  font-family: 'Raleway', sans-serif;

  // Bold
  @if($weight == bold) {
    font-weight: 700;
  }

  // Semi Bold
  @else if($weight == semibold) {
    font-weight: 600;
  }
  

  // regurar
  @else {
    font-weight: 400;
  }
}

// ------------------------------------------------------------------------------------------------------------------
//
//  モード変更のトランジション
//
// ------------------------------------------------------------------------------------------------------------------
@mixin mode-transition() {
  //transition: 0.3s;
}


// ------------------------------------------------------------------------------------------------------------------
//
//  ホバー時の下線
//
// ------------------------------------------------------------------------------------------------------------------
@mixin hover-underline($type: fade, $dir: null, $weight: 2px, $color: #000) {
  @if $dir == null {
    @if $type == fade {
      $dir: 'top';
    } @else if $type == slide {
      $dir: 'center';
    }
  }

  position: relative;
  display: inline-block;
  text-decoration: none;

  &::after {
    position: absolute;
    left: 0;
    content: '';
    width: 100%;
    height: $weight;
    background: $color;
    
    @if $type == fade {
      transition: .3s;
      opacity: 0;
      visibility: hidden;
      @if $dir == bottom {
        bottom: $weight * -4;
      } @else {
        bottom: $weight;
      }
    } @else if $type == slide {
      bottom: $weight * -2;
      transform: scale(0, 1);
      transition: transform .3s;
      @if $dir == left-right or $dir == right-in-left {
        transform-origin: left top;
      } @else if $dir == right-left or $dir == left-in-right {
        transform-origin: right top;
      } @else {
        transform-origin: center top;
      }
    }

    @include mqSP() {
      display: none;
    }
  }

  &:hover::after {
    @if $type == fade {
      bottom: $weight * -2;
      opacity: 1;
      visibility: visible;
    } @else if $type == slide {
      @if $dir == left-in-right {
        transform-origin: left top;
      } @else if $dir == right-in-left {
        transform-origin: right top;
      }
      transform: scale(1, 1);
    }
  }
}