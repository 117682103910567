/*
 *
 *  固定ページのスタイル
 *
 */

// 必ず読み込む共通パーツ
@use "ini/variables" as *;
@use "ini/mixins" as *;

// リスト
@use "parts/mixins-ol-ul" as *;

// テーブル
@use "parts/mixins-table" as *;


// ------------------------------------------------------------------------------------------------------------------
//
//  ヘッダー
//
// ------------------------------------------------------------------------------------------------------------------
.page__header {
  background: #F9FAF8;
  padding: size-vh(115) var(--content-margin) size-vh(48);
  @include mode-transition();

  &__container {
    padding: 0 var(--content-margin-plus);

    h1.page-header__heading {
      @include raleway(bold);
      font-size: size(90);
      line-height: 1.5; // 1.1だと日本語がきつい
      position: relative;

      &::before {
        position: absolute;
        content: '';
        display: block;
        width: size(48);
        height: 3px;
        background: $gradient-deep-90deg;
        left: 0;
        bottom: size-vh(12);
      }

      @include mqSP() {
        font-size: size-vw(180);

        &::before {
          bottom: size-vh(4);
          width: 0.75em;
        }
      }
    }

    .page__header__heading--ja {
      margin-top: size(16);
      line-height: 1;

      @include mqSP() {
        font-size: size(14);
      }
    }

    .page__header__description {
      margin-top: size(24);
      font-size: size(14);
    }
  }

  //
  // 汎用ページ
  // -----------------
  &.page__header--normal {
    padding-top: size-vh(150);
    padding-bottom: size-vh(52);
    
    .page__header__container h1 {
      font-size: size(64);

      &::before {
        bottom: size-vh(4);
      }

      @include mqSP() {
        font-size: size-vw(144);

        &::before {
          bottom: size-vh(-4);
        }
      }
    }
  }
}


// 
// ダークテーマ
// 
.dark-theme .page__header {
  background: #131313;

  &__container {

    h1 {
      color: #fff;
    }
  }
}


// ------------------------------------------------------------------------------------------------------------------
//
//  コンテンツエリア
//
// ------------------------------------------------------------------------------------------------------------------
.page__content {
  padding: size-vh(64) var(--content-margin) size-vh(80);

  &__container {
    padding: 0 var(--content-margin-plus);

    a:not(.button):hover {
      opacity: 0.7;
    }

    p:not(:last-child) {
      margin-bottom: 1em;
    }

    // リスト設定
    @include ol-ul();

    // テーブル設定
    @include table();
  }
}
// 
// ダークテーマ
// 
.dark-theme .page__content .page__content__container  {
  h2, h3, h4 {
    color: #fff;
  }
}

// ------------------------------------------------------------------------------------------------------------------
//
//  テーブル追加設定
//
// ------------------------------------------------------------------------------------------------------------------
table.page__content__table {
  margin: 0 var(--content-inner-narrow-margin);
  width: calc(100% - (var(--content-inner-narrow-margin) * 2));

  tr {

    th, td {
      padding: size(32) 0;
    }

    th {
      width: size-vw(240);
      padding-right: size(24);
      text-align: left;
      vertical-align: top;

      @include mqSmallPC(){
        width: 30%;
      }
    }
  }

  @include mqSP() {
    tr {

      th{
        display: block;
        border-width: 0;
        width: 100%;
        padding-bottom: 0;
      }

      td {
        padding-top: size(24);
        display: block;
      }
    }
  }
}
