/*
 *
 *  トップページで読み込むスタイル
 *
 */

@use "sass:color";

// 必ず読み込む共通パーツ
@use "ini/variables" as *;
@use "ini/mixins" as *;

@keyframes contact-arrow {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    left: 100%;
  }
}

// ------------------------------------------------------------------------------------------------------------------
//
//  ヘッダー背景
//
// ------------------------------------------------------------------------------------------------------------------
.top__headerBg {
  background: #F9FAF8;
  width: 100%;
  height: size(440);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  @include mqSP() {
    height: size-vh(380);
  }
}

// ------------------------------------------------------------------------------------------------------------------
//
//  ヒーローイメージ 
//
// ------------------------------------------------------------------------------------------------------------------
.top__hero {
  padding-top: size(100);

  .top__hero__image {
    position: relative;
    margin-left: var(--content-margin);
    background-image: url(../images/top_hero.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: calc(100% - var(--content-margin));
    height: size(500);
    padding: size(40) size(48);

    @include mqSP() {
      height: size-vh(400);
      padding: size(22) size(20);
    }

    @include mqSmallSP() {
      padding: size(12) size(20);
    }

    &::before {
      position: absolute;
      content: '';
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $gradient-135deg;
      opacity: 0.8;
    }

    .top__hero__copy {
      position: relative;
    }

    .top__hero__lgCopy {
      color: #fff;
      font-size: size(40);
      font-weight: bold;
      line-height: 1.7;
      margin: 0;
      font-family: "Hiragino Sans";
      text-shadow: 1px 2px 7px rgba(0, 0, 0, 0.17);

      @include mqSP() {
        font-size: size(26);
        line-height: 1.5;
      }

      @include mqSmallSP() {
        font-size: size(24);
      }
    }

    .top__hero__smCopy {
      color: #fff;
      font-size: size(16);
      font-weight: bold;
      margin: size(24) 0 0;
      line-height: 1.7;

      @include mqSP() {
        font-size: size(13);
        margin-top: size(20);
      }

      @include mqSmallSP() {
        br {
          display: none;
        }
      }
    }
  }
}


// 
// ダークテーマ
// 
.dark-theme .top__hero {
  background: #131313;
}


// ------------------------------------------------------------------------------------------------------------------
//
//  お知らせ
//
// ------------------------------------------------------------------------------------------------------------------
.top_news {
  background: #fff;
  margin-top: size-vh(-160);
  padding-top: size-vh(160);

  .top_news__list {
    list-style: none;
    margin: 0 var(--content-margin);
    padding: size(24) 0 size(32);

    @include mqSP() {
      padding: size(8) 0 size(24);
    }
  }

  .top_news__item {
    padding: size(12) 0;
    position: relative;

    &::after {
      content: '';
      background-image: linear-gradient(to right, #D9D9D9, #D9D9D9 5px, transparent 5px, transparent 10px);
      background-size: 10px 1px;
      background-repeat: repeat-x;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
    }
  }

  .top_news__link {
    text-decoration: none;
    color: #000;
    display: flex;

    @include mqSP() {
      display: block;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .top_news__date {
    flex: 0 0 size(132);
    font-size: size(15);
    font-weight: bold;

    @include mqSP() {
      font-size: size(14);
    }
  }

  .top_news__title {
    @include mqSP() {
      font-size: size(15);
      padding-top: size(4);
    }
  }
}

// 
// ダークテーマ
// 
.dark-theme .top_news {
  background: #1f1f1f;

  .top_news__item::after {
    background-image: linear-gradient(to right, #656565, #656565 5px, transparent 5px, transparent 10px);
  }

  .top_news__link {
    color: #fff;
  }

  .top_news__date {
    opacity: 0.75;
  }
}


// ------------------------------------------------------------------------------------------------------------------
//
//  事業内容
//
// ------------------------------------------------------------------------------------------------------------------
.top__service {
  padding-top: size-vh(92);
  background: linear-gradient(135deg, #fdfbf0 0%, #edf8e1 100%);

  @include mqSP() {
    padding-top: size-vh(48);
  }

  .top__service__container {
    margin: 0 var(--content-margin);
    display: flex;

    @include mqSP() {
      display: block;
    }

    h2 {
      margin: 0;
      flex: 0 0 size(348);
      line-height: 1.1;
  
      span {
        @include raleway(bold);
        font-size: size(60);
        display: block;
        position: relative;

        &::before {
          position: absolute;
          content: '';
          display: block;
          width: size(48);
          height: 3px;
          background: $gradient-deep-90deg;
          left: 0;
          bottom: -3px;

          @include mqSP() {
            bottom: -5px;
          }
        }
      }
  
      small {
        margin-top: size(19);
        display: block;
        font-size: size(13);
        font-weight: normal;
        opacity: 0.7;
      }
    }
  
    .top__service__content {
      flex: 1 1 auto;
      
      @include mqSP() {
        margin-top: size(52);
      }

      ul {
        list-style: none;
  
        li {
          display: flex;
          
          &:not(:first-child) {
            margin-top: size(42);
          }
  
          > div:first-child {
            flex: 0 0 size(64);

            img {
              width: size(64);
              height: auto;
            }

            // 画像
            .top__service__image--dark {
              display: none;
            }
            .top__service__image--light {
            display: block;
            }

            @include mqSP() {
              flex: 0 0 size(36);

              img {
                width: size(36);
                // box-shadow: 0 0 0 1px rgba(#fff, 0.2) inset;
                // box-shadow: 0 0 0 1px rgba(#000, 0.4) inset;
                border-radius: 50%;
              }
            }
  
            img {
              width: 100%;
            }
          }
  
          > div:last-child {
            flex: 1 1 auto;
            padding-left: size(24);

            @include mqSP() {
              padding-left: size(16);
            }
  
            h3 {
              font-size: size(20);
              margin: 0;
              font-weight: bold;
            }
  
            p {
              margin: size(20) 0 0;
              font-size: size(15);
              font-weight: normal;
              // color: rgba(#fff, 0.8);

              @include mqSP() {
                margin-top: size(16);
                margin-left: size(-52);
              }
            }
          }
        }
      }
    }
  }

  //
  // お問い合わせ
  // -----------------
  .top__service__contact {
    text-decoration: none;
    height: size-vh(64);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: size-vh(92);
    width: 100%;
    position: relative;

    @include mqSP() {
      margin-top: size-vh(48);
      padding: 1rem var(--content-margin);
      height: auto;
    }

    &::before {
      position: absolute;
      content: '';
      display: block;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: $gradient-deep-90deg;
      transition: all 0.3s;
    }

    &:hover::before {
      filter: contrast(120%);
      transition: all 0.3s;
    }

    p {
      flex: 0 1 auto;
      position: relative;
      color: #fff;
      font-weight: bold;
      margin: 0;
      line-height: 1;
      padding-right: size(8);

      @include mqSP() {
        line-height: 1.7;
      }
    }

    .top__service__contact__arrow {
      flex: 0 0 size(24);
      margin-right: size(16);
      position: relative;
      height: size(16);

      > div {
        position: absolute;
        left: 0;
        top: 0;
        animation: contact-arrow 2s ease-in-out infinite;
        font-size: 0;

        &:nth-child(2) {
          animation-delay: -1s;
        }

        img {
          height: size(16);
          width: auto;
        }
      }
    }
  }
}

// 
// ダークテーマ
// 
.dark-theme .top__service {
  background: #1a1a1a;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);

  .top__service__container .top__service__content {
    ul li > div {
      &:first-child {
        .top__service__image--dark {
          display: block;
        }
        .top__service__image--light {
          display: none;
        }
      }

      &:last-child p {
        color: $d-text-color;
      }
    }
  }

  .top__service__contact {
    &::before {
      opacity: 0.75;
    }

    &:hover::before {
      opacity: 0.8;
    }
  }
}


// ------------------------------------------------------------------------------------------------------------------
//
//  ブログ
//
// ------------------------------------------------------------------------------------------------------------------
.top__blog {
  padding: size-vh(92) var(--content-margin);

  h2 {
    margin: 0;
    line-height: 1.1;
    text-align: center;

    span {
      @include raleway(bold);
      font-size: size(60);
      display: inline-block;
      position: relative;

      &::before {
        position: absolute;
        content: '';
        display: block;
        width: size(48);
        height: 3px;
        background: $gradient-deep-90deg;
        left: 50%;
        transform: translateX(-50%);
        bottom: -3px;

        @include mqSP() {
          bottom: -5px;
        }
      }
    }

    small {
      margin-top: size(19);
      display: block;
      font-size: size(13);
      font-weight: normal;
      opacity: 0.7;
    }
  }

  .blog-list {
    margin-top: size-vh(52);
  }

  .top__blog__button-container {
    text-align: center;
  }
}

// 
// ダークテーマ
// 
.dark-theme .top__blog {

  h2 {
    color: #fff;
  }
}


// ------------------------------------------------------------------------------------------------------------------
//
//  リンクとか
//
// ------------------------------------------------------------------------------------------------------------------
.top__link {
  display: flex;
  flex-wrap: wrap;
  color: #fff;
  padding: 0 size(20);

  @include mqSP() {
    display: block;
    padding: 0;
  }

  h2 {
    font-size: size(42);
    @include raleway(bold);
    position: relative;
    text-align: center;
    line-height: 1.1;
    margin: 0;

    &::before {
      position: absolute;
      content: '';
      display: block;
      width: size(48);
      height: 3px;
      background: rgba(#FFF39B, 0.5);
      left: 50%;
      transform: translateX(-50%);
      bottom: -3px;
    }
  }

  p {
    margin-top: size-vh(32);
    font-size: size(15);
    font-weight: bold;
  }

  .button {
    margin-top: size-vh(40);
  }

  > div {
    padding: size-vh(48);
    text-align: center;
    color: #fff;

    @include mqSP() {
      padding-left: var(--content-margin);
      padding-right: var(--content-margin);
    }
  }

  .top__link__recruit {
    flex: 0 0 calc(50% - #{size(6)});
    margin-right: size(6);
    background: linear-gradient(#41bc96 0%, #41d099 100%);

    @include mqSP() {
      margin-right: 0;
    }

    .button.button--inverse:not(.button--disabled):hover {
      color: #41bc96;
    }
  }

  .top__link__contact {
    flex: 0 0 calc(50% - #{size(6)});
    margin-left: size(6);
    background: linear-gradient(#28b2a2 0%, #25ccc6 100%);

    @include mqSP() {
      margin-left: 0;
      margin-top: size(4);
    }

    .button.button--inverse:not(.button--disabled):hover {
      color: #25ccc6;
    }
  }

  .top__link__lab {
    flex: 0 0 100%;
    padding: size(12) 0;

    @include mqSP() {
      padding: size(4) 0 size(8);
    }

    a {
      display: block;
      padding: size-vh(48);
      text-align: center;
      color: #fff;
      text-decoration: none;
      transition: all 0.3s;
      background: linear-gradient(#0065a3 0%, #0d6faa 100%);

      &:hover {
        opacity: 0.95;
      }
    }

    h2 {
      line-height: 1;
      font-size: 0;

      &::before {
        content: none;
      }

      img {
        height: size(40);
      }
    }

    p {
      font-size: size(14);
      font-weight: bold;
      margin-top: size(20);
    }
  }
}

// 
// ダークテーマ
// 
.dark-theme .top__link {

  .top__link__recruit {
    background: linear-gradient(rgba(#41bc96,0.5) 0%, rgba(#41d099,0.5) 100%);

    .button.button--inverse:not(.button--disabled):hover {
      color: color.adjust(#41bc96, $lightness: -10%);
    }
  }

  .top__link__contact {
    background: linear-gradient(rgba(#28b2a2,0.5) 0%, rgba(#25ccc6,0.5) 100%);

    .button.button--inverse:not(.button--disabled):hover {
      color: color.adjust(#25ccc6, $lightness: -10%);
    }
  }

  .top__link__lab {

    a {
      padding: size(36);
      background: linear-gradient(rgba(#0065a3, 0.5) 0%, rgba(#0d6faa, 0.5) 100%);

      &:hover {
        background: linear-gradient(rgba(#0065a3, 0.55) 0%, rgba(#0d6faa, 0.55) 100%);
      }
    }
  }
}