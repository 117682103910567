/*
 *
 *  Table of Contents Plus
 *
 */

// 必ず読み込む共通パーツ
@use "../ini/variables" as *;
@use "../ini/mixins" as *;

div#toc_container {
  background: #f4f4f3;
  padding: 1em 1.5em;
  width: 100%;
  display: table;
  font-size: 95%;
  margin: var(--text-margin-g) 0;

  p {
    line-height: 1.8;
    margin-top: 0.25rem;
  }

  p.toc_title {
    text-align: center;
    font-weight: bold;
    margin: 0;
    padding: 0;
  }

  span.toc_toggle {
    font-weight: normal;
    font-size: 90%;

    a {
      color: #000;
    }
  }

  .toc_list {

    > li {
      margin-bottom: 0.5em;
    }

    a {
      color: #000;
      text-decoration: none;
    }
  }

  ul li ul {
    margin-top: 0.25em;
    margin-left: 1.5em;
  }

  .toc_number {
    padding-right: 0.5em;
    font-weight: bold;
  }

  ul, li {
    margin: 0;
    padding: 0;
  }
}

#toc_container.no_bullets li,
#toc_container.no_bullets ul,
#toc_container.no_bullets ul li,
.toc_widget_list.no_bullets,
.toc_widget_list.no_bullets li {
  background: 0 0;
  list-style-type: none;
  list-style: none;
}


// 
// ダークテーマ
// 
.dark-theme div#toc_container {
  background: #262626;

  span.toc_toggle a,
  a {
    color: #fff;
  }
}