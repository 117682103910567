//
//
//  変数
//
//

$full-menu_icon_transition : cubic-bezier(0.694,  0.048, 0.335, 1.000);

$l-border-color: #f0f0f0;
$l-hover-color: rgba(#111100, 0.03);
$l-danger-color: #E00000;

$d-text-color: #dadada;
$d-hover-color: rgba(#fff, 0.05);
$d-tag-color: #6AED7E;
$d-danger-color: #FC6262;

$gradient-deep-90deg: linear-gradient(90deg, rgba(13,186,66,1) 0%, rgba(19,186,141,1) 100%);

$gradient-135deg: linear-gradient(135deg, #7fc952 0%, #51b961 50%, #069f99 100%);
$gradient-90deg: linear-gradient(90deg, #7fc952 0%, #51b961 50%, #069f99 100%);

$gradient-base: rgb(19,186,141);
$gradient-base2: rgb(13,186,66);


