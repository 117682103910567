/*
 *
 *  各ページ固有のスタイル
 *
 */

// 必ず読み込む共通パーツ
@use "ini/variables" as *;
@use "ini/mixins" as *;

// ------------------------------------------------------------------------------------------------------------------
//
//  会社情報
//
// ------------------------------------------------------------------------------------------------------------------
.page-company__map {
  margin-top: size(80);
  width: 100%;
  height: size(360);

  iframe {
    width: 100%;
    height: 100%;
  }
}

.page-company__map__button-container {
  margin-top: size(32);
  text-align: right;

  @include mqSP() {
    text-align: center;
  }
}


// ------------------------------------------------------------------------------------------------------------------
//
//  プロジェクト
//
// ------------------------------------------------------------------------------------------------------------------
.page-projects__container {
  margin: 0 var(--content-inner-narrow-margin);

  h2:first-child {
    margin-top: size(16);
  }

  h2:not(:first-child) {
    margin-top: size(80);
  }

  h2:not(:last-child) {
    margin-bottom: size(16);
  }

  img {
    height: auto;
  }
}


// ------------------------------------------------------------------------------------------------------------------
//
//  フォーム送信完了
//
// ------------------------------------------------------------------------------------------------------------------
.page-contact--complete-container {
  margin: 0 var(--content-inner-narrow-margin);
}


// ------------------------------------------------------------------------------------------------------------------
//
//  執筆者一覧
//
// ------------------------------------------------------------------------------------------------------------------
ul.page-members__list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;

  @include mqSP() {
    margin-top: -3vh;
  }

  li {
    flex: 0 0 25%;
    padding: 0 0 3vw;
    text-align: center;

    @include mqSP() {
      flex: 0 0 50%;
    }

    a {
      color: #000;
      text-decoration: none;
      padding: 2vw 0;
      display: block;

      @include mqSP() {
        padding: 3vh 0 2vh;
      }

      &:hover {
        opacity: 1;
        background: $l-hover-color;
      }

      .page-members__image {
        padding: 0 5vw;

        img {
          width: 100%;
          border-radius: 50%;

          @include mqSP() {
            width: 80%;
          }
        }
      }
  
      .page-members__name {
        font-size: size(18);
        font-weight: bold;
        margin-top: size-vh(24);
        display: block;
        margin-bottom: 0;
      }
  
      .page-members__position {
        opacity: 0.5;
        font-size: size(14);
        margin-top: size-vh(8);
      }
    }
  }
}


// 
// ダークテーマ
// 
.dark-theme ul.page-members__list {

  li a {
    color: #fff;

    &:hover {
      background: $d-hover-color;
    }

    .page-members__position {
      opacity: 0.7;
    }
  }
}